// @flow
import * as React from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import MatchingGiftInput from 'components/MatchingGift/Input'
import DonateOption from 'components/DonateOption'
import * as Form from 'components/Form'

import DonationAmountField from './DonationAmountField'
import DonationsDesignationsField from './DonationsDesignationsField'

import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        donationType: ?string,
        customDonation: ?string,
        donationAmount: ?string,
        cardType: ?string,
        matchingGift: ?string,
        comments: ?String
    }
}

function LeadGenForm (props: Props) {
    const { handleFieldChange, values, isLoading } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const isSalisburyFallAppeal2022 = React.useMemo(() => state.campaign.id === 'daea729e-0454-11ed-b6c3-0242ac140006', [state.campaign])

    values.anonymousDonation = values.anonymousDonation || false

    const phoneField = React.useMemo(() => {
        if (props.values.mobilePhone) {
            return 'mobilePhone'
        }

        if (props.values.homePhone) {
            return 'homePhone'
        }

        for (let field in props.values) {
            if (field.endsWith('Phone') && props.values[field]) {
                return field
            }
        }

        return 'mobilePhone'
    }, [props.values])


    const paymentGatewayConfig = React.useMemo(() => {
        const index = Object.keys(state.campaign['payment-gateways'])[0]
        return state.campaign['payment-gateways'][index]
    }, [state.campaign])

    const onDonationOption1 = React.useCallback(() => {
        handleFieldChange('donationType', 'one-time')
    }, [handleFieldChange])

    const onDonationOption2 = React.useCallback(() => {
        handleFieldChange('donationType', 'monthly')
    }, [handleFieldChange])

    const handleComments = React.useCallback(value => {
        return handleFieldChange('comments', value)
    }, [handleFieldChange])

    const defaultYearOptions = React.useMemo(() => {
        const maxDate = new Date().getFullYear()
        const dates = {}

        for (let i = maxDate; i >= 1900; i--) {
            dates[i] = i
        }

        return dates
    }, [])

    React.useEffect(() => {
        if (paymentGatewayConfig?.monthlySubscriptionActive === false) {
            handleFieldChange('donationType', 'one-time')
        }
    }, [handleFieldChange, paymentGatewayConfig])

    React.useEffect(() => {
        if (state.campaign['matching-gift-gateway']) {
            window.addEventListener('doublethedonation_company_id', function (event) {
                const companyId = event.detail
                const companyName = document.getElementsByName('doublethedonation_entered_text')[0]
                if (companyId && companyName) {
                    handleFieldChange('matchingGiftCompanyId', companyId, () => {
                        handleFieldChange('matchingGiftCompanyName', companyName.value)
                    })
                }
            }, false)
        }
    }, [state.campaign, handleFieldChange])

    return (
        <div
            className={cn(styles.form, { [styles.isSalisburyFallAppeal2022]: isSalisburyFallAppeal2022 })}
        >
            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    {!isSalisburyFallAppeal2022 && (
                        <>
                            {paymentGatewayConfig?.monthlySubscriptionActive && <>
                                <div className={styles.title}>
                                    <TextBlock
                                        blockId={'leadGenForm.fields.donationType.label'}
                                        defaultContent={`<p>${t('fields.donationType.label')}</p>`}
                                        inline
                                        onAnchorClick={createClickActivity}
                                    />
                                </div>

                                <Form.RowColumn size={1}>
                                    <Form.Field>
                                        <div className={styles.donateOptionGroup}>
                                            <DonateOption
                                                center
                                                small
                                                title={t('fields.donationType.options.one-time')}
                                                onClick={onDonationOption1}
                                                active={values.donationType === 'one-time'}
                                            />
                                            <DonateOption
                                                center
                                                small
                                                title={t('fields.donationType.options.monthly')}
                                                onClick={onDonationOption2}
                                                active={values.donationType === 'monthly'}
                                            />
                                        </div>
                                    </Form.Field>

                                    <div className={styles.spanText}>
                                        <p>{t('fields.donationType.helper', { type: values.donationType })}</p>
                                    </div>
                                </Form.RowColumn>
                            </>}

                            <div className={styles.title}>
                                <TextBlock
                                    blockId={'leadGenForm.fields.donationAmount.label'}
                                    defaultContent={`<p>${t('fields.donationAmount.label')}</p>`}
                                    inline
                                    onAnchorClick={createClickActivity}
                                />
                            </div>

                            <DonationAmountField
                                selectedAmount={values.donationAmount}
                                donationDesignations={values.donationDesignations}
                                handleFieldChange={handleFieldChange}
                                t={t}
                            />

                            <div className={styles.title}>
                                <TextBlock
                                    blockId={'leadGenForm.fields.donationDesignations.label'}
                                    defaultContent={`<p>${t('fields.donationDesignations.label')}</p>`}
                                    inline
                                    onAnchorClick={createClickActivity}
                                />
                            </div>

                            <DonationsDesignationsField
                                selectedAmount={values.donationAmount}
                                selectedDesignations={values.donationDesignations}
                                handleFieldChange={handleFieldChange}
                                t={t}
                            />

                            <div className={styles.title}>
                                <TextBlock
                                    blockId={'leadGenForm.fields.matchingDonation.label'}
                                    defaultContent={`<p>${t('fields.matchingDonation.label')}</p>`}
                                    inline
                                    onAnchorClick={createClickActivity}
                                />
                            </div>

                            <Form.RowColumn size={1}>
                                <Form.Field>
                                    <div className={styles.donationGift}>
                                        <DonateOption
                                            center
                                            small
                                            title={t('fields.matchingDonation.options.none')}
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'none')}
                                            active={values.matchingGiftCompany === 'none'}
                                        />

                                        <DonateOption
                                            center
                                            small
                                            title={t('fields.matchingDonation.options.own-company')}
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'own-company')}
                                            active={values.matchingGiftCompany === 'own-company'}
                                        />

                                        <DonateOption
                                            center
                                            small
                                            title={t('fields.matchingDonation.options.spouse-company')}
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'spouse-company')}
                                            active={values.matchingGiftCompany === 'spouse-company'}
                                        />
                                    </div>
                                </Form.Field>

                                {(values.matchingGiftCompany === 'spouse-company' || values.matchingGiftCompany === 'own-company') && values.matchingGiftCompany !== null && values.matchingGiftCompany !== 'none'
                                    ? state.campaign['matching-gift-gateway']
                                        ? <MatchingGiftInput/>
                                        : <Form.Field
                                                defaultValue={values.matchingGiftCompanyName || null}
                                                onChange={value => handleFieldChange('matchingGiftCompanyName', `${value}`)}
                                                type="text"
                                                placeholder={t('fields.companyName')}
                                        />
                                    : null
                                }
                            </Form.RowColumn>
                        </>
                    )}

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'leadGenForm.personalInformation.label'}
                            defaultContent={`<p>${t('title.personalInformation')}</p>`}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1} className={styles.row1}>
                        <Form.Field
                            defaultValue={values.firstName || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('firstName', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.name')}
                        />

                        <Form.Field
                            defaultValue={values.lastName || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('lastName', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.lastname')}
                        />

                        <Form.Field>
                            <Form.Dropdown
                                placeholder={t('fields.year')}
                                options={defaultYearOptions}
                                required={false}
                                onChange={value => {
                                    handleFieldChange('classYear', parseInt(value))
                                }}
                                defaultValue={values.classYear ? values.classYear.toString() : null}
                            />
                        </Form.Field>
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row2}>
                        <Form.Field
                            defaultValue={values.addressLine1 || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('addressLine1', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.address')}
                        />

                        <Form.Field
                            defaultValue={values.addressLine2 || null}
                            onChange={value => { handleFieldChange('addressLine2', `${value}`) }}
                            type="number"
                            required={false}
                            placeholder={t('fields.unit')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            defaultValue={values.city || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('city', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.city')}
                        />

                        <Form.Field
                            defaultValue={values.province || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('province', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.state')}
                        />

                        <Form.Field
                            defaultValue={values.postalCode || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('postalCode', `${value}`)
                            }}
                            type="number"
                            placeholder={t('fields.zipcode')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            defaultValue={values[phoneField] || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange(phoneField, `${value}`)
                            }}
                            type="phone"
                            placeholder={t('fields.phone')}
                        />

                        <Form.Field
                            defaultValue={values.email || null}
                            required={!isSalisburyFallAppeal2022}
                            onChange={value => {
                                handleFieldChange('email', `${value}`)
                            }}
                            type="email"
                            placeholder={t('fields.email')}
                        />
                    </Form.RowColumn>

                    {!isSalisburyFallAppeal2022 && (
                        <>
                            <Form.RowColumn size={1}>
                                <Form.Field>
                                    <Form.Checkbox
                                        defaultChecked={values.anonymousDonation}
                                        onChange={() => {
                                            handleFieldChange('anonymousDonation', !values.anonymousDonation)
                                        }}
                                        label={t('fields.anonymousDonation.label')}
                                    />
                                </Form.Field>
                            </Form.RowColumn>

                            <div className={styles.title}>
                                <TextBlock
                                    blockId={'leadGenForm.fields.comments.label'}
                                    defaultContent={`<p>${t('fields.comments.label')}</p>`}
                                    inline
                                    onAnchorClick={createClickActivity}
                                />
                            </div>

                            <Form.RowColumn size={1}>
                                <Form.Textarea
                                    defaultValue={values.comments || null}
                                    onChange={handleComments}
                                    type="textarea"
                                    required={false}
                                    rows={9}
                                    placeholder={t('fields.comments.placeholder')}
                                />
                            </Form.RowColumn>
                        </>
                    )}
                </Form.RowColumns>
            </Form.Row>

            <div className={styles.separator}/>

            <Form.Row>
                <Form.RowColumns className={styles.submitContainer}>
                    <Form.SubmitBlock
                        loading={isLoading}
                        blockId={'leadGenForm.submit'}
                        label={t('submit.label')}
                        style={isSalisburyFallAppeal2022 ? {
                            fontFamily: '"Arial Black", sans-serif',
                            fontWeight: 900,
                            width: 220
                        } : null}
                    />
                </Form.RowColumns>
            </Form.Row>
        </div>
    )
}

LeadGenForm.defaultProps = {
    values: {
        matchingGift: 'None',
        anonymousDonation: false
    }
}

export default withForm(true)(withTheme(supportedThemes)(LeadGenForm))
